import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset32 = createTheme({
  name: 'preset32',
  config: {
    // แสดงปุ่มฝากถอนในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: true,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 80,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 60,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 10,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#A84141", 0.4),
    primary: {
      // สีหลัก
      // main: "#38101a",
      main: "#B3B6B5",
      // main: "radial-gradient(100% 100% at 50% 0,#d1ae4c 0,#64511e 100%)",

      // สีตัวอักษร
      contrastText: "#656564",
    },
    secondary: {
      // สีรอว
      main: "#290B12",
      // สีตัวอักษร
      contrastText: "white",
    },
    background: {
      // สีพื้นหลัง
      default: "#621627 ",
      // สี Card
      paper: "#290B12",
    },
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: "/assets/bgs/bg_wisdom168.png",
    
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: 0.2,
    bodySize: "cover",
    bodySizeMobile: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "-190px -50px",
  },
 
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#5F3F47",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#312026 ",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "linear-gradient(180deg, rgba(151, 151, 151,1),rgba(122, 124, 125,1), rgba(122, 112, 111,1), rgba(81, 81, 79,1), rgba(122, 112, 111,1),rgba(122, 124, 125,1),rgba(151, 151, 151,1))",
    // 100: "linear-gradient(180deg, rgba(190, 192, 194,1) 21%, rgba(161, 162, 163,1) 50%, rgba(112, 112, 111,1) 75%, rgba(161, 162, 163,1) 100%)",
    // สีกระเป๋าตอนรับโบนัส
    200: "linear-gradient(140deg, rgba(180,176,176,1) 0%, rgba(147,138,138,1) 40%, rgba(232,232,232,1) 100%)",
    // สี Card
    300: "linear-gradient(180deg, #290B12, #38101a, #4B2730, #5F3F47 ,#4B2730 , #38101a, #290B12)",
    // สี Appbar
    // 400: "linear-gradient(90deg, rgba(60,44,15,1) 0%, rgba(10,20,6,1) 30%, rgba(10,20,6,1) 90%, rgba(50,36,11,1) 100%)",
    400: "linear-gradient(180deg, #290B12, #38101a, #4B2730, #5F3F47 ,#4B2730 , #38101a, #290B12)",
  },
  wheel: {
    // ขอบกงล้อ
    borderColor: "#fdd741",
    segment: {
      // สีกงล้อ 1 
      fillStyle1: "#c0c0c0",
      // สีกงล้อ 2
      fillStyle2: "#aa0505",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              background: preset32.palette.primary.main,
            }),
          ...(ownerState.variant === "outlined" && {
            color: preset32.palette.primary.main,
            borderColor: preset32.palette.primary.main,
          }),
        }),
      },
    },
  },
});

export default preset32;
