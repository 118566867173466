import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const defaultPreset = createTheme({
  name: 'default',
  config: {
    // แสดงปุ่มฝากถอนในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: true,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 120,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 100,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 3,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#e6c361", 0.4),
    // สีหลัก
    primary: {
      main: "#e6c361",
      contrastText: "#171203",
    },
    // สีลอง
    secondary: {
      main: "#02080a",
      contrastText: "white",
    },
    background: {
      // สีพื้นหลัง
      default: "#100D39",
      // สี Card
      paper: "#390D35",
    },
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: "/assets/bgs/bg_violet.png",
    // body: "#081D23",
    bodySize: "cover",
    // backgroundPosition: "center", 
    // backgroundAttachment: "fixed", 
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: 0.6,
  },
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#33005A",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#100D39",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "linear-gradient(197deg, rgba(230,195,97,1) 21%, rgba(184,151,59,1) 50%, rgba(157,125,37,1) 75%, rgba(184,151,59,1) 100%)",
    // สีกระเป๋าตอนรับโบนัส
    200: "linear-gradient(140deg, rgba(180,176,176,1) 0%, rgba(147,138,138,1) 40%, rgba(232,232,232,1) 100%)",
    // สี Card
    300: "linear-gradient(180deg, #100D39, #260D39,#390D35)",
    // สี Appbar
    // 400: "linear-gradient(180deg, #33005A, #260D39)",
    400: "linear-gradient(180deg, #100D39, #260D39,#390D35)",

    500: "linear-gradient(180deg, #100D39, #260D39,#390D35)",

  },
  wheel: {
    // ขอบกงล้อ
    borderColor: "#e6c308 ",
    segment: {
      // สีกงล้อ 1
      fillStyle1: "#EFF2C6",
      // สีกงล้อ 2
      fillStyle2: "#33005A",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" && {
            background: defaultPreset.palette.primary.main,
          }),
          ...(ownerState.variant === "outlined" && {
            color: defaultPreset.palette.primary.main,
            borderColor: defaultPreset.palette.primary.main,
          }),
        }),
      },
    },
  },
});

export default defaultPreset;
